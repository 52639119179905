import React, { useState, useEffect } from 'react'

import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import EventEdit from './EventEdit'
import GroupBarChart from './charts/GroupBarChart';
import Main from "../components/layout/Main";
import MonthWiseBarChart from './charts/MonthWiseBarChart';
import { Modal, ListGroup } from "react-bootstrap";

const Home = () => {

  const [newLead, setNewLead] = useState("");
  const [allContacts, setAllCOntacts] = useState("");
  const [totalBusiness, setTotalBusiness] = useState("");
  const [showEventModel, setShowEventModel] = useState(false);
  const calendarRef = React.createRef();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [workingLeads, setWorkingLeads] = useState(0);

  const navigate = useNavigate();
  const [studentCountArr, setStudentCountArr] = useState([]);
  const [staffCountArr, setstaffCountArrr] = useState([]);
  const [branchArr, setBranchArr] = useState([]);
  const [monthName, setMonthName] = useState([]);
  const [monthCount, setMonthCount] = useState([]);
  const [monthdata, setmonthdata] = useState([]);
  useEffect(() => {
    //const st = "Open - Not Contactecd"
    async function init() {
      // const fetchNewLeads = await SparkApi.fetchNewLeads();
      const fetchNewLeads = await SparkApi.fetchLead();
      // console.log('fetchNewLeads',fetchNewLeads)
      let filterdata = fetchNewLeads.filter((value, index, array) => value.leadstatus === 'Open - Not Contacted')
      setNewLead(filterdata.length)
      let filterworkingLead = fetchNewLeads.filter((value, index, array) => value.leadstatus === 'Work in Progress')
      setWorkingLeads(filterworkingLead.length)
      const fetchallcontacts = await SparkApi.fetchallcontacts();
      const fetchTotalBusiness = await SparkApi.fetchTotalBusiness();
      const leadCountByStatus = await SparkApi.runReportByName('lead_count_by_status');

      const allMeetings = await SparkApi.fetchAllMeetings('today');
      //('allMeetings', allMeetings)

      ////('leadCountByStatus' , leadCountByStatus)
      setTodayMeetings(allMeetings ? allMeetings : []);
      ////('fetchTotalBusiness:', fetchTotalBusiness);
      // setNewLad(fetchNewLeads.total);
      setAllCOntacts(fetchallcontacts.total);
      setTotalBusiness(fetchTotalBusiness.total)
      //////("fetchNewLeads" , fetchNewLeads);
      //////("fetchLeads=>" + JSON.stringify(fetchNewLeads));
      //////("fetchallcontacts=>" + JSON.stringify(fetchallcontacts));
      //////("fetchallActiveusers=>" + JSON.stringify(fetchallActiveusers));
      ////('lead counts', leadCountByStatus);
      
      // let countWorking = 0;
      // leadCountByStatus.forEach(function (item) {

      //   allLeadStatus.forEach(function (val) {
      //     if (val.is_converted === false && val.is_lost === false && val.label === item.leadstatus) {
      //       countWorking += parseInt(item.count);
      //     }
      //   })
      // })

      const leads = await SparkApi.fetchLead();
      // setWorkingLeads(leads.length);


    }

    init();
  }, []);




  const createLead = () => {
    navigate(`/leads/e`);
  };


  const submitEvents = async (eventRec) => {
    //////('eventRec home submit', eventRec)
    setShowEventModel(false);
  }
  const [meetinglist, setMeetinglist] = useState([]);

  useEffect(() => {
    fetchAllTodaysMeeting();
  }, []);

  const fetchAllTodaysMeeting = async () => {
    try {
      const allMeetings = await SparkApi.fetchAllMeetings("today");
      //("Fetched Meetings:", allMeetings);
      setMeetinglist(allMeetings);
     
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };


  return (

    <Container>

      <Row >
        <Col lg={4}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#f7981c' }}></i>
                <i className="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">NEW LEADS</h6>
                <h1 className='mb-0 d-inline '>{newLead}</h1><Badge bg="light" text="dark">Running</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={4}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #FFD700' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#FFD700' }}></i>
                <i className="fa-solid fa-coins fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">WORKING LEADS</h6>
                <h1 className='mb-0 d-inline '>{workingLeads}</h1> <Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={4}>
          <Link to="/contacts" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i className="fa-solid fa-user-tie fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">CUSTOMERS</h6>
                <h1 className='mb-0 d-inline '>{allContacts}</h1><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        {/* <Col lg={3}>
          <Link to="/users" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> 

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i className="fa-solid fa-arrow-trend-up fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">REVENUE</h6>
                <h1 className='mb-0 d-inline '>{parseFloat(totalBusiness/100000).toFixed(2)}</h1><Badge bg="light" text="dark"> ₹ Lakh</Badge>
              </div>
            </div>
          </Link>
        </Col> */}


      </Row>
      <Row className="mt-5">
        <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >MONTHLY LEAD REPORT</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '350px' }}>
                  <MonthWiseBarChart />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>

        <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >STAFF LEADS PROGRESS</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '100%' }}>
                  <GroupBarChart />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>


        <Col lg={6} className="mt-3">

          <div style={{ height: '350px' }}>

            <Card className="">
              <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>QUICK ACTIONS</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Text>
                      <div width>
                        <Button className=" btn-primary btn-sm my-2 btn-block" style={{ width: '80%' }} onClick={() => createLead(true)}> <i className="fa-solid fa-bolt"></i> NEW LEAD</Button>

                      </div>
                      <div>
                        <Button className=" btn-custom btn-sm btn-block" style={{ width: '80%' }} onClick={() => setShowEventModel(true)}> <i className="fa-solid fa-list-check" ></i> NEW MEETINGS</Button>

                      </div>
                    </Card.Text>
                  </Col>
                  <Col>
                    <center>
                      <div ><i className="fa-regular fa-calendar-days fa-2x" style={{ color: "#E73F59" }}></i></div>
                      <p className='pt-2'><i>You have <Link to={"/meetings/today"}>
                        <b>{todayMeetings?.length}</b>
                      </Link> meetings today</i></p>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer>
            </Card>
          </div>
        </Col>




        <Col lg={6} className="mt-3">

          <div style={{ height: '350px' }}>

            <Card className="">
              <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>Todays Meeting Schedule</Card.Header>
              <Card.Body>
                <Row>

                  <Col>
                    <center>
                      {meetinglist && meetinglist.length > 0 ? (
                        <p className='pt-2'>
                            <ListGroup>
                              {meetinglist.map((meeting, index) => (
                                <ListGroup.Item key={index}>
                                  <h5>{meeting.title} <Badge>{meeting.status}</Badge> </h5>
                                  <p>
                                    <strong>Priority</strong> <Badge bg="danger">{meeting.priority}</Badge><strong>Start Time:</strong> {new Date(meeting.startdatetime).toLocaleString()}

                                  </p>
                                
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                        </p>
                      ) : (
                        <p>No meetings available.</p>
                      )}
                    </center>
                  </Col>

                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer>
            </Card>
          </div>
        </Col>
      </Row>
      {showEventModel && (
        <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          //eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
      )}
    </Container>

  )
}

export default Home
