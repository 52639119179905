import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import Confirm from "./Confirm";


const EventRecEdit = (props) => {
    const [validated, setValidated] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [eventRec, setEventRec] = useState(props.eventRec ? props.eventRec : {});
    const [selectedUser, setSelectedUser] = useState('');
    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());
    const [userInfo, setUserInfo] = useState(jwt_decode(localStorage.getItem("token")));
    // added a state for leads
    const [leads,setLeads]= useState(props?.leads?props?.leads:null);
    
    // useEffect(() => {
    //     const fetchData = async () => {
    //         let tempValue = {};
    //         tempValue.value = userInfo.id;
    //         tempValue.label = userInfo.username;
    //         setoption(tempValue);

    //         let filteruserdata = await SparkApi.fetchUserById(userInfo.id);
    //         console.log('filteruserdata', filteruserdata)
    
    //         let filteruserdataManager = await SparkApi.fetchUserById(filteruserdata.managerid);
    //         console.log('filteruserdataManager', filteruserdataManager)
    
    //         eventRec.toemail = filteruserdata.email;
    //         eventRec.ccemail = filteruserdataManager.email;
    //     };

    //     if (props.eventRec.id === undefined || props.eventRec.id === null) {
    //         fetchData();
    //     }
    // }, []);

    useEffect(() => {
        // console.log("Meeting(EVENT)",eventRec);
        ////('eventRec 11',eventRec)
        let userInfo = jwt_decode(localStorage.getItem('token'));

        if (props?.eventRec?.id) {
            let temp = {}
            temp.value = props?.eventRec?.ownerid;
            temp.label = props?.eventRec?.ownername;

            setoption(temp);
        } else {
            eventRec.priority = 'Low';
            eventRec.status = 'Not Started';
            if (!eventRec.startdatetime) {
                eventRec.startdatetime = moment(new Date()).format('MM/DD/YYYY HH:mm');
                eventRec.enddatetime = moment(new Date()).format('MM/DD/YYYY HH:mm');
                eventRec.ownerid = userInfo.id;
                eventRec.ownername = userInfo.username;
            }
        }

        async function init() {
            const result = await SparkApi.fetchUsers();
            if (result) {
                let ar = [];
                result.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.username;
                    ar.push(obj);
                });
                //////('ar', ar)
                setOptionUsers(ar);
            } else {
                setOptionUsers([]);
            }
        }
        init();
    }, []);



    const handleChange = (e) => {
        //////(e.target.value)
        setEventRec({ ...eventRec, [e.target.name]: e.target.value });
        //////('eventRec', eventRec)
    };



    const handleDelete = async (e) => {
        setModalShow(true);


    };

    const deleteTask = async (e) => {
        setModalShow(false);
        e.preventDefault();


        if (eventRec.id) {
            ////(eventRec)
            const result = await SparkApi.deleteTask(eventRec.id);
            ////('result update:', result);
            if (result) {

                props.deleteEvents(eventRec);
            }
        }
    };


    //////('if call', eventRec.startdatetime,option);





    const checkRequredFields = () => {
        if ((eventRec.title && eventRec.title.trim() !== '') && eventRec.startdatetime && eventRec.enddatetime &&
            (eventRec.status && eventRec.status.trim() !== '') && (eventRec.priority && eventRec.priority.trim() !== '') && (eventRec.description && eventRec.description.trim() !== '')) {
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        //////('if call', eventRec.startdatetime,option);

        if (eventRec.startdatetime === null || eventRec.startdatetime === undefined) {
            eventRec.startdatetime = new Date(eventRec.startdatetime).toISOString;
            eventRec.targetdate = new Date(eventRec.startdatetime).toISOString;

        } else if (eventRec.enddatetime === null || eventRec.enddatetime === undefined) {
            eventRec.enddatetime = new Date(eventRec.enddatetime).toISOString;
        }

        e.preventDefault();
        if (checkRequredFields()) {
            setValidated(true);
            return;
        }

        if (eventRec.id) {
            ////('result update')
            ////(eventRec)
            let filteruserdata = await SparkApi.fetchUserById(option.value);
            // console.log('filteruserdata', filteruserdata)
            eventRec.managerid = filteruserdata.managerid
            // console.log('eventrec',eventRec)
            const result = await SparkApi.saveTask(eventRec);
            if (result && result.success) {
               
                eventRec['start'] = eventRec.startdatetime;
                eventRec['end'] = eventRec.enddatetime;

                submitEvents(eventRec);
            }
        } else {
            ////('result insert')
            delete eventRec.parentid;
            delete eventRec.lastmodifieddate;
            ////('eventRec', eventRec);
            let emailInfo = {}
            // eventRec.toemail = 'shivangitak9@gmail.com'
            // eventRec.ccemail = 'shivangi.t@ibirdsservices.com'
            // let user = {"username": "Sumit Agarwal", "email" : "sumit.a@idealinsurance.in"}
            emailInfo.to = eventRec.toemail;
            // emailInfo.cc = eventRec.ccemail
            emailInfo.editorHtml = `<p><strong>Hi,</strong></p>
                    <p>We are pleased to inform you that a new meeting schedule task has been created:</p>
                    <p><strong>Task Title:</strong>${eventRec.title}</p>
                    <p><strong>Task Description:</strong> ${eventRec.description}</p>
                    <p><strong>Meeting Date:</strong> ${moment(eventRec.startdatetime).format('DD-MM-YYYY, hh:mm A')}</p>
                    <p>Please make sure to attend the meeting on time.</p>
                    <br>
                    <p><strong>Thanks and Regards,</strong></p>
                    <p>Ideal Insurance</p>`;
            emailInfo.subject = "Meeting Schedule Notification";
            emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
            emailInfo.parentid = userInfo.id;
            let sendemail = SparkApi.sendEmailTask(emailInfo);
            // console.log('email send in loop', emailInfo)

            eventRec.type = 'Meeting';
            eventRec.fromemail = userInfo.email
            eventRec.managerid = selectedusermanagerid.managerid
            //    console.log('eventrec',eventRec)
            const result = await SparkApi.createTask(eventRec);

            ////('result insert', result)
            if (result) {
                eventRec['id'] = result.id;
                eventRec['start'] = result.startdatetime;
                eventRec['targetdate'] = result.startdatetime;
                eventRec['end'] = result.enddatetime;
                eventRec['title'] = result.title;
                eventRec['description'] = result.description;
                eventRec['type'] = result.type;
                eventRec['priority'] = result.priority;
                eventRec['status'] = result.status;
                eventRec['ownerid'] = result.ownerid;
                eventRec['ownername'] = result.ownername;

                submitEvents(eventRec);
            }
        }

    };



    const submitEvents = (eventRec) => {
        props.submitEvents(eventRec);
    };

    const [selectedusermanagerid, setselectedusermanagerid] = useState({ "managerid": "" });
    const handleUsers = async (event) => {
        let filteruserdata = await SparkApi.fetchUserById(event.value);
        // console.log('filteruserdata', filteruserdata)
        selectedusermanagerid.managerid = filteruserdata.managerid
        let filteruserdataManager = await SparkApi.fetchUserById(filteruserdata.managerid);
        // console.log('filteruserdataManager', filteruserdataManager)
        setoption(event)
        setSelectedUser(event)
        //////('eventRec', eventRec)

        setEventRec({ ...eventRec, ownerid: event.value, ownername: event.label, toemail: filteruserdata.email, ccemail: filteruserdataManager.email });

    }

    return (
        <>
            {modalShow &&
                <Confirm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    deleteTask={deleteTask}
                    title="Confirm delete?"
                    message="You are going to delete the record. Are you sure?"
                    table="task"
                />}

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {eventRec.id ? 'Edit Meeting' : 'Create Meeting'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="view-form">
                        <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="eventRecEdit">
                            <Row>

                            {/* Added a new field lead_id 24 Sept 2024 @ali */}
                                <Col lg={12}>
                                    <Form.Group className="mx-3" >
                                        <Form.Label className="form-view-label" htmlFor="lead">
                                            Lead
                                        </Form.Label>
                                        <Form.Select name="lead_id" value={eventRec?.lead_id} placeholder="Select a lead" onChange={handleChange} >
                                            <option value="">--Select Lead--</option>
                                            {leads && leads.map((lead) => <option value={lead.value}>{lead.label}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mx-3">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicTitle"
                                        >
                                            Title
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="title"
                                            placeholder="Enter title"
                                            value={eventRec.title}
                                            onChange={handleChange}

                                        />
                                    </Form.Group>
                                </Col>



                                <Col lg={6}>
                                    <Form.Group className="mx-3">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPriority"
                                        >
                                            Priority
                                        </Form.Label>
                                        <Form.Select required aria-label="Enter Priority" name="priority" value={eventRec.priority} onChange={handleChange}>
                                            <option value="">Select Priority</option>
                                            <option value="Low">Low</option>
                                            <option value="Normal">Normal </option>
                                            <option value="High">High</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>


                                <Col lg={6}>
                                    <Form.Group className="mx-3">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicTargetdate"
                                        >
                                            Start Date Time
                                        </Form.Label>

                                        <Form.Control
                                            required
                                            type="datetime-local"
                                            name="startdatetime"
                                            value={moment(eventRec.startdatetime).format('YYYY-MM-DDTHH:mm')}
                                            onChange={handleChange}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mx-3" >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicTargetdate"
                                        >
                                            End Date Time {new Date(eventRec.targetdate).toISOString}
                                        </Form.Label>

                                        <Form.Control
                                            required
                                            type="datetime-local"
                                            name="enddatetime"
                                            value={moment(eventRec.enddatetime).format('YYYY-MM-DDTHH:mm')}
                                            //defaultValue={defaultTargetDate}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="mx-3">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicStatus"
                                        >
                                            Status
                                        </Form.Label>
                                        <Form.Select required aria-label="Enter status" name="status" value={eventRec.status} onChange={handleChange}>
                                            <option value="">Select Status</option>
                                            <option value="Not Started">	Not Started</option>
                                            <option value="In Progress">In Progress </option>
                                            <option value="Completed">Completed</option>
                                            <option value="Waiting">Waiting</option>
                                            <option value="Deferred">Deferred</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mx-3">
                                        <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                            Assign Staff
                                        </Form.Label>
                                        <Select

                                            name="ownerid"
                                            value={option}
                                            className="custom-select username"
                                            onChange={handleUsers}
                                            options={optionUsers}
                                            getOptionValue={(option) => option.value}
                                        />



                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group className="mx-3" >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicDescription"
                                        >
                                            Description
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            name="description"
                                            placeholder="Enter description"
                                            value={eventRec.description}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>



                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}  >Save</Button>

                    {eventRec.id && <Button variant="danger" onClick={handleDelete}  >Delete</Button>}
                    <Button onClick={props.onHide} variant="light">Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EventRecEdit;
